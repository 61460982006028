import axios, { Axios } from 'axios'
import { retry, exponentialBackoff, WaitTime, parallel } from '@splunkdlt/async-tasks'
import { Attribution, AttributionV2, AttributionV3 } from '../types/attributions'
import { chunkArray } from './transform'
import { StringMap } from '../types/attributions'
import { Category } from '../types/attributions'

export interface ApiResponse<T = any> {
  success: boolean
  message?: string
  data?: T
  username?: string
  body?: any
}

interface UserInfo {
  username: string
  email: string
  account: string
  isAdmin?: boolean
}

export interface NullFieldsResponse {
  address: string
  network: string
  attribution: string
  category: string
  source: string
  note?: string
  rowNumber: number
}

export interface ConflictingCategoryResponse {
  attribution: string
  originalCategoryId: number
  conflictingCategoryId: number
  rowNumber: number
}

export interface ValidationResponse {
  statusCode: number
  message: string
  existingAddresses?: string[]
  similarlyNamedAttributions?: SimilarFields[]
  nullFields?: NullFieldsResponse[]
  conflictingCategories?: ConflictingCategoryResponse[]
  currentPage?: number
}

export interface SimilarFields {
  field: string
  isSimilarTo: Array<string>
  rowNumber: number
}

export interface ConsolidatedSimilarFields {
  field: string
  isSimilarTo: Array<string>
  count: number
}

export interface ReducedValidationResponse
  extends Omit<ValidationResponse, 'similarlyNamedAttributions' | 'conflictingCategories'> {
  similarlyNamedAttributions?: ConsolidatedSimilarFields[]
  conflictingCategories?: ConsolidatedConflictingCategoryResponse[]
}

export interface ConsolidatedConflictingCategoryResponse extends Omit<ConflictingCategoryResponse, 'rowNumber'> {
  count: number
}

export interface UploadResponse {
  jobId: string
  filename: string
}

export interface UploadState {
  started: number
  lastUpdated: number
  files: Array<string>
}

export interface ValidationState {
  started: number
  lastUpdated: number
  finished?: number
  files: string[]
  validatedFiles: string[]
  results?: ReducedValidationResponse
}

export interface InsertionState {
  started: number
  lastUpdated: number
  finished?: number
  files: string[]
  insertedFiles: string[]
}

export interface UploadStateWithId extends UploadState {
  jobId: string
}

export interface ValidationStateWithId extends ValidationState {
  jobId: string
}

export interface InsertionStateWithId extends InsertionState {
  jobId: string
}

export interface JobStatesResponse {
  upload: UploadStateWithId[]
  validation: ValidationStateWithId[]
  insertion: InsertionStateWithId[]
}

export interface EditAddressRequest {
  address: string
  network: string
  attributionId: number
  user: string
  validated: boolean
  source: string
  note?: string
  timeAdded?: Date
  updated?: Date
  oldNetwork: string
  oldSource: string
}

// elasticsearch
export interface SearchRequestBody {
  searchString: string
  mode: 'match' | 'wildcard'
  results?: number
}

export interface AutocompleteRequestBody {
  searchString: string
  mode: 'wildcard' | 'suggest'
  results?: number
  network?: string
}

export type UrlWithParams = (params: any) => string

export interface UrlMap {
  [key: string]: UrlWithParams | string
}

const MAX_REQUESTS_COUNT = 5
const INTERVAL_MS = 10
let PENDING_REQUESTS = 0

const urls: UrlMap = {
  // auth
  signIn: '/api/authenticateuser',
  signOut: '/api/signout',
  userSeen: '/api/userseen',
  checkAuth: '/api/checkauth',
  getUser: '/api/getuser',
  // admin
  getUsers: '/api/admin/getusers',
  registerUser: '/api/admin/registeruser',
  genCodes: '/api/admin/gencodes',
  getCodes: '/api/admin/getcodes',
  genApiKey: '/api/admin/genapikey',
  getApiKeys: '/api/admin/getapikeys',
  // addresses
  getAddresses: '/api/addresses/get',
  addAddresses: '/api/addresses/add',
  // validation
  validateAttributions: '/api/addresses/validate',
  // file validations
  uploadAttributionsFile: '/api/addresses/upload',
  validateAttributionsFiles: '/api/addresses/validatefiles',
  insertAttributionsFiles: '/api/addresses/insertfiles',
  listJobs: '/api/addresses/list-jobs',
  getJobUploadState: (jobId: string) => `/api/addresses/upload-state/${jobId}`,
  getJobValidateState: (jobId: string) => `/api/addresses/validate-state/${jobId}`,
  getJobInsertState: (jobId: string) => `/api/addresses/insert-state/${jobId}`,
  deleteJob: (jobId: string) => `/api/addresses/delete/${jobId}`,

  editAddress: '/api/addresses/edit',
  deleteAddress: '/api/addresses/delete',
  addressesCount: '/api/addresses/count',
  verifyAddress: '/api/addresses/verify',
  addressesStats: '/api/addresses/stats',
  refreshAddressStats: '/api/addresses/refreshStats',
  // uniqueAddresses: (datasource: string) => `/api/addresses/uniqueaddresses/datasource/${datasource}`,
  uniqueNetworks: (datasource: string) => `/api/addresses/uniquenetworks/datasource/${datasource}`,
  // search and autocomplete
  search: ({ dataset }: { dataset: string }) => `/api/search/${dataset}`,
  autocomplete: ({ dataset }: { dataset: string }) => `/api/search/autocomplete/${dataset}`,
  // attributions
  uniqueAttributions: '/api/attributions/unique',
  uniqueNetworksForAttribution: '/api/addresses/networks',
  getAttributions: '/api/attributions/get',
  getLocalAttributions: '/api/attributions/getlocal',
  getActiveAttributions: '/api/attributions/getactive',
  getLocalAttributionsMapping: '/api/attributions/getlocalmapping',
  getAttribution: '/api/attributions/getone',
  addAttribution: '/api/attributions/add',
  addAttributions: '/api/attributions/addbulk',
  mergeAttributions: '/api/attributions/merge',
  attributionsCount: (datasource: string) => `/api/attributions/count/${datasource}`,
  // categories
  getCategory: '/api/categories/getcategory',
  getCategories: (datasource: string) => `/api/categories/datasource/${datasource}`,
  addCategory: '/api/categories/add',
  categoryStats: '/api/categories/stats',
  // gemini advisory
  getGeminiGroups: '/api/attributions/getgeminigroups',
  getGeminiSourceIds: '/api/attributions/getgeminisourceids',
  // youtube scans
  getYouTubeScans: '/api/videoscans/get',
  getYouTubeScansCount: '/api/videoscans/count',
  dispatchYouTubeLambda: '/api/videoscans/dispatchlambda',
  dispatchYouTubeQuery: '/api/videoscans/dispatchquery',
  dispatchYouTubeQueries: '/api/videoscans/dispatchqueries',
  downloadFrame: '/api/videoscans/getaddressframesnapshot'
}

const defaultOptions = {
  headers: {
    'Content-Type': 'application/json',
    credentials: 'include'
  },
  withCredentials: true
}

const multipartOptions = {
  headers: {
    credentials: 'include' // leave off Content-Type when using FormData
  },
  withCredentials: true
}

export class Api {
  private waitAfterFailure: WaitTime
  private apiClient: Axios
  private multipartApiClient: Axios

  constructor() {
    this.waitAfterFailure = exponentialBackoff({ min: 500, max: 5_000 })
    this.apiClient = axios.create(defaultOptions)
    // this.apiClient.interceptors.request.use(function (config) {
    //   return new Promise((resolve, reject) => {
    //     let interval = setInterval(() => {
    //       if (PENDING_REQUESTS < MAX_REQUESTS_COUNT) {
    //         PENDING_REQUESTS++
    //         clearInterval(interval)
    //         resolve(config)
    //       }
    //     }, INTERVAL_MS)
    //   })
    // })
    this.multipartApiClient = axios.create(multipartOptions)

    /**
     * Axios Response Interceptor
     */
    // this.apiClient.interceptors.response.use(
    //   function (response) {
    //     PENDING_REQUESTS = Math.max(0, PENDING_REQUESTS - 1)
    //     return Promise.resolve(response)
    //   },
    //   function (error) {
    //     if (error.response.status === 429) {
    //       // If the error has status code 429, retry the request
    //       return axios.request(error.config)
    //     }
    //     PENDING_REQUESTS = Math.max(0, PENDING_REQUESTS - 1)
    //     return Promise.reject(error)
    //   }
    // )
  }

  // request methods

  private async get(url: string): Promise<ApiResponse> {
    const response = await this.apiClient.get(url)
    return response.data as any as ApiResponse
  }

  private async post(url: string, params: any): Promise<ApiResponse> {
    const response = await this.apiClient.post(url, params)
    return response.data as any as ApiResponse
  }

  private async postMultipart(
    url: string,
    form: FormData,
    onUploadProgress?: (progressEvent: ProgressEvent) => void
  ): Promise<ApiResponse> {
    const opts = onUploadProgress != null ? { onUploadProgress } : undefined
    const response = await this.multipartApiClient.post(url, form, opts)
    return response.data as any as ApiResponse
  }

  public async getExternal(url: string): Promise<any | undefined> {
    const response = await axios.get(url)
    if (response.status < 400) {
      return response.data
    }
    return undefined
  }

  // auth

  public async signIn(username: string, password: string): Promise<{ success: boolean }> {
    const response = await this.post(<string>urls.signIn, { username, password })
    if (response.success) {
      return { success: true }
    }
    return { success: false }
  }

  public async signOut() {
    const response = await this.get(<string>urls.signOut)
  }

  public async userSeen(username: string): Promise<ApiResponse> {
    return await this.post(<string>urls.userSeen, { username })
  }

  public async checkAuth(): Promise<ApiResponse> {
    return await this.get(<string>urls.checkAuth)
  }

  public async getUsers(): Promise<ApiResponse> {
    return await this.get(<string>urls.getUsers)
  }

  public async getUser(username: string): Promise<ApiResponse<UserInfo>> {
    return await this.post(<string>urls.getUser, { username })
  }

  public async signUp(username: string, password: string, email: string, code: string): Promise<ApiResponse> {
    return await this.post(<string>urls.registerUser, { username, password, email, code })
  }

  public async getAddresses(page: number, count: number, query: StringMap): Promise<ApiResponse> {
    return await this.post(<string>urls.getAddresses, { page, count, ...query })
  }

  public async validateAddresses(
    data: AttributionV2[],
    filename: string,
    retryAttempts: number,
    chunkSize: number,
    updateFunction?: (response: ValidationResponse, index: number) => void
  ) {
    const chunks = chunkArray(data, chunkSize)
    const responses: ApiResponse[] = []
    await parallel(
      chunks.map((chunk) => async () => {
        const requestChunk = {
          items: chunk,
          uuid: filename
        }
        responses.push(
          await retry(
            () => {
              return this.post(<string>urls.validateAttributions, requestChunk)
            },
            {
              attempts: retryAttempts,
              waitBetween: this.waitAfterFailure,
              taskName: 'validation chunk',
              warnOnError: false,
              onRetry: (attempt: number) => console.log(`Retrying to validate chunk to db (attempt ${attempt})`)
            }
          )
        )
      }),
      {
        maxConcurrent: MAX_REQUESTS_COUNT
      }
    )
    responses.forEach((response, index) => {
      if (updateFunction) {
        const responseAsObject: any = JSON.parse(response.data)
        const validation: ValidationResponse = {
          statusCode: responseAsObject.statusCode,
          message: responseAsObject.message,
          existingAddresses:
            responseAsObject.existingAddresses && responseAsObject.existingAddresses != ''
              ? JSON.parse(responseAsObject.existingAddresses)
              : [],
          similarlyNamedAttributions:
            responseAsObject.similarlyNamedAttributions && responseAsObject.similarlyNamedAttributions != ''
              ? JSON.parse(responseAsObject.similarlyNamedAttributions)
              : undefined,
          nullFields:
            responseAsObject.nullFields && responseAsObject.nullFields != ''
              ? JSON.parse(responseAsObject.nullFields)
              : undefined,
          conflictingCategories:
            responseAsObject.conflictingCategories && responseAsObject.conflictingCategories != ''
              ? JSON.parse(responseAsObject.conflictingCategories)
              : undefined,
          currentPage: responseAsObject.currentPage
        }
        updateFunction(validation, index)
      }
    })
  }

  //data: AttributionV2[]
  public async uploadAttributionsFile(
    data: FormData,
    user: string,
    onUploadProgress: (progressEvent: ProgressEvent) => void
  ): Promise<ApiResponse<{ jobId: string; filename: string }>> {
    data.append('user', user)
    return this.postMultipart(<string>urls.uploadAttributionsFile, data, onUploadProgress)
  }

  public async validateAttributionsFiles(
    jobId: string,
    user: string,
    filenames: string[]
  ): Promise<ApiResponse<ReducedValidationResponse>> {
    return this.post(<string>urls.validateAttributionsFiles, { jobId, filenames, user })
  }

  public async insertAttributionsFiles(jobId: string, user: string, filenames: string[]): Promise<ApiResponse<any>> {
    return this.post(<string>urls.insertAttributionsFiles, { jobId, filenames, user })
  }

  public async listAllValidationJobs(): Promise<ApiResponse<JobStatesResponse>> {
    return this.get(<string>urls.listJobs)
  }

  public async getJobUploadState(jobId: string): Promise<ApiResponse<UploadState>> {
    return this.get((<UrlWithParams>urls.getJobUploadState)(jobId))
  }

  public async getJobValidationState(jobId: string): Promise<ApiResponse<ValidationState>> {
    return this.get((<UrlWithParams>urls.getJobValidateState)(jobId))
  }

  public async getJobInsertionState(jobId: string): Promise<ApiResponse<InsertionState>> {
    return this.get((<UrlWithParams>urls.getJobInsertState)(jobId))
  }

  public async deleteJob(jobId: string): Promise<ApiResponse<any>> {
    return this.get((<UrlWithParams>urls.deleteJob)(jobId))
  }

  public async sendAddresses(
    data: AttributionV2[],
    retryAttempts: number,
    updateFunction?: (current: number, total: number) => void
  ): Promise<ApiResponse> {
    const chunks = chunkArray(data, 500)
    for (let i = 0; i < chunks.length; i++) {
      const chunk = chunks[i]
      await retry(
        async () => {
          return await this.post(<string>urls.addAddresses, chunk)
        },
        {
          attempts: retryAttempts,
          waitBetween: this.waitAfterFailure,
          taskName: 'sendAttributions chunk',
          warnOnError: false,
          onRetry: (attempt: number) => console.log(`Retrying to send chunk to db (attempt ${attempt})`)
        }
      )
      if (updateFunction) {
        updateFunction(i + 1, chunks.length)
      }
    }
    return { success: true, message: 'Done sending attributions' }
  }

  public async getAllAttributions(
    total: number,
    retryAttempts: number,
    count: number = 5000,
    status: string,
    updateFunction: (results: any[], status: string) => void
  ): Promise<ApiResponse> {
    const promises: ApiResponse[] = []
    const numPages = Math.ceil(total / count)
    const numPagesArray = Array.from(Array(numPages).keys())
    let url = ''
    if (status === 'local') {
      url = <string>urls.getLocalAttributions
    } else if (status === 'master') {
      url = <string>urls.getAttributions
    } else {
      url = <string>urls.getActiveAttributions
    }
    await parallel(
      numPagesArray.map((page) => async () => {
        promises.push(
          await retry(
            () => {
              return this.post(url, { page, count, query: {} })
            },
            {
              attempts: retryAttempts,
              waitBetween: this.waitAfterFailure,
              taskName: 'get attribution chunk',
              warnOnError: false,
              onRetry: (attempt: number) => console.log(`Retrying to validate chunk to db (attempt ${attempt})`)
            }
          )
        )
      }),
      {
        maxConcurrent: MAX_REQUESTS_COUNT
      }
    )
    promises.forEach((response) => {
      if (updateFunction) {
        const responseAsObject: any[] = response.data.results
        updateFunction(responseAsObject, status)
      }
    })
    return { success: true, message: 'Done getting attributions' }
  }

  public async addAddress(item: Attribution): Promise<ApiResponse> {
    return await this.post(<string>urls.addAddress, [item])
  }

  public async editAddress(edited: EditAddressRequest[]): Promise<ApiResponse> {
    return await this.post(<string>urls.editAddress, edited)
  }

  public async deleteAddress(item: AttributionV2): Promise<ApiResponse> {
    return await this.post(<string>urls.deleteAddress, {
      address: item.address,
      network: item.network,
      attributionId: item.attributionId,
      source: item.source
    })
  }

  public async getAddressesCount(query: StringMap = {}, estimate: boolean): Promise<ApiResponse> {
    return await this.post(<string>urls.addressesCount, { ...query, estimate: estimate })
  }

  public async getAttributionCount(local: boolean = false): Promise<ApiResponse> {
    const datasource = local ? 'local' : 'master'
    return await this.get((<UrlWithParams>urls.attributionsCount)(datasource))
  }

  // search & autocomplete

  public async search<T>({ dataset, payload }: { dataset: string; payload: SearchRequestBody }): Promise<ApiResponse> {
    const url = (<UrlWithParams>urls.search)({ dataset })
    return await this.post(url, payload)
  }

  public async autocomplete({
    dataset,
    payload
  }: {
    dataset: string
    payload: AutocompleteRequestBody
  }): Promise<ApiResponse> {
    const url = (<UrlWithParams>urls.autocomplete)({ dataset })
    return await this.post(url, payload)
  }

  public async getAddressesStats(): Promise<ApiResponse> {
    return await this.get(<string>urls.addressesStats)
  }

  public async refreshAddressStats(): Promise<ApiResponse> {
    return await this.get(<string>urls.refreshAddressStats)
  }

  public async getUniqueAddresses(dataSource: string): Promise<ApiResponse> {
    return await this.get((<UrlWithParams>urls.uniqueAddresses)(dataSource))
  }

  public async getUniqueAttributions(): Promise<ApiResponse> {
    return await this.get(<string>urls.uniqueAttributions)
  }

  public async getAttributions(page: number, count: number, query: StringMap): Promise<ApiResponse> {
    return await this.post(<string>urls.getAttributions, { page, count, ...query })
  }

  public async getLocalAttributions(page: number, count: number): Promise<ApiResponse> {
    return await this.post(<string>urls.getLocalAttributions, { page, count })
  }

  public async getActiveAttributions(page: number, count: number): Promise<ApiResponse> {
    return await this.post(<string>urls.getActiveAttributions, { page, count })
  }

  public async getLocalAttributionsMapping(attributionIds: number[]): Promise<ApiResponse> {
    return await this.post(<string>urls.getLocalAttributionsMapping, { attributionIds })
  }

  public async addAttribution(item: AttributionV3): Promise<ApiResponse> {
    return await this.post(<string>urls.addAttribution, item)
  }

  public async addAttributions(items: AttributionV3[]): Promise<ApiResponse> {
    return await this.post(<string>urls.addAttributions, items)
  }

  public async mergeAttributions(main: AttributionV3, toMerge: AttributionV3[]): Promise<ApiResponse> {
    const subs = toMerge.map((att) => att.attributionId)
    return await this.post(<string>urls.mergeAttributions, { mainAttributionId: main.attributionId, subs: subs })
  }

  public async getUniqueNetworks(dataSource: string): Promise<ApiResponse> {
    return await this.get((<UrlWithParams>urls.uniqueNetworks)(dataSource))
  }

  public async getUniqueNetworksForAttribution(attributionIds: number[]): Promise<ApiResponse> {
    return await this.post(<string>urls.uniqueNetworksForAttribution, { attributionIds: attributionIds })
  }

  public async getGeminiGroups(): Promise<ApiResponse> {
    return await this.get(<string>urls.getGeminiGroups)
  }

  public async getGeminiSourceIds(): Promise<ApiResponse> {
    return await this.get(<string>urls.getGeminiSourceIds)
  }

  public async getSignupCodes(): Promise<ApiResponse> {
    return await this.get(<string>urls.getCodes)
  }

  public async genSignupCodes(): Promise<ApiResponse> {
    return await this.post(<string>urls.genCodes, { count: 5 })
  }

  public async getApiKeys(): Promise<ApiResponse> {
    return await this.get(<string>urls.getApiKeys)
  }

  public async genApiKey(username: string): Promise<ApiResponse> {
    return await this.post(<string>urls.genApiKey, { username, level: 1 })
  }

  public async getCategories(dataSource: string): Promise<ApiResponse> {
    return await this.get((<UrlWithParams>urls.getCategories)(dataSource))
  }

  public async addCategory(category: Category): Promise<ApiResponse> {
    return await this.post(<string>urls.addCategory, { ...category })
  }

  // youtube scans

  public async getYouTubeScans(
    page: number,
    count: number,
    sortBy: string,
    sortDesc: boolean,
    author?: string
  ): Promise<ApiResponse> {
    return await this.post(<string>urls.getYouTubeScans, {
      limit: count,
      skip: page * count,
      sortBy,
      sortDesc,
      author
    })
  }

  public async getYouTubeScansCount(author?: string): Promise<ApiResponse> {
    return await this.post(<string>urls.getYouTubeScansCount, { author })
  }

  public async dispatchLambda(videoId: string, author: string): Promise<ApiResponse> {
    return await this.post(<string>urls.dispatchYouTubeLambda, { videoId, numLambdas: 20, author })
  }

  public async dispatchQuery(query: string, numVideos: number, author: string): Promise<ApiResponse> {
    return await this.post(<string>urls.dispatchYouTubeQuery, { query, numVideos, numLambdas: 20, author })
  }

  public async dispatchQueries(queries: string[], videosPerQuery: number, author: string): Promise<ApiResponse> {
    return await this.post(<string>urls.dispatchYouTubeQueries, { queries, videosPerQuery, numLambdas: 20, author })
  }

  public async downloadFrame(videoId: string, addr: string): Promise<ApiResponse> {
    // @ts-ignore
    const res = await this.post(<string>urls.downloadFrame, { videoId, address: addr })
    const base64 = res.data.toString().replaceAll('"', '')
    return base64
  }
}
