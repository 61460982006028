type BitcoinNetworkType = 'bitcoin'
type EthereumNetworkType = 'ethereum'
type TronNetworkType = 'tron'
export type NetworkTypes = BitcoinNetworkType | EthereumNetworkType | TronNetworkType | ''

export interface NetworkClassifications extends 
  Partial<BitcoinClassifications>,
  Partial<EthereumClassifications>,
  Partial<TronClassifications>
{
  networkTypes: NetworkTypes[]
}

// bitcoin start
export interface BitcoinClassifications {
  isBitcoinBlock: boolean
  isBitcoinTxid: boolean
  isBitcoinLegacyAddress: boolean
  isBitcoinBech32Address: boolean
  isBitcoinBech32mAddress: boolean
  isBitcoinAddress: boolean
}

export function isBitcoinClassification(classification: UntypedNetwork | unknown): classification is BitcoinClassifications {
  return (
    (classification as BitcoinClassifications).isBitcoinBlock ||
    (classification as BitcoinClassifications).isBitcoinTxid ||
    (classification as BitcoinClassifications).isBitcoinLegacyAddress ||
    (classification as BitcoinClassifications).isBitcoinBech32Address ||
    (classification as BitcoinClassifications).isBitcoinBech32mAddress
  )
}
// bitcoin end

// ethereum start
export interface EthereumClassifications {
  isEthBlockOrHash: boolean
  isEthAddress: boolean
}

export function isEthereumClassification(classification: UntypedNetwork | unknown): classification is EthereumClassifications {
  return (
    (classification as EthereumClassifications).isEthAddress ||
    (classification as EthereumClassifications).isEthBlockOrHash
  )
}
// ethereum end

// tron start
export interface TronClassifications {
  isTronBlock: boolean
  isTronTxid: boolean
  isTronAddress: boolean
}

export function isTronClassification(classification: UntypedNetwork | unknown): classification is TronClassifications {
  return (
    (classification as TronClassifications).isTronAddress ||
    (classification as TronClassifications).isTronBlock ||
    (classification as TronClassifications).isTronTxid
  )
}
// tron end

export type UntypedNetwork = (BitcoinClassifications | EthereumClassifications | TronClassifications)

/*
  regex notes
  tron block hash
  (?!0x)0{3,}[0-9a-fA-F]{61,}

  tron tx hash
  (?!0x)(?!0{3})[0-9a-fA-F]{64}

  tron address
  T[1-9A-HJ-NP-Za-km-z]{33}

  ethereum block hash (same as tx)
  0x[a-fA-F0-9]{64}

  ethereum tx hash (same as block)
  0x[a-fA-F0-9]{64}

  ethereum address
  0x[a-fA-F0-9]{40}

  bitcoin block hash
  000000[a-f0-9]{58}

  bitcoin txid
  [a-f0-9]{64}

  bitcoin addresses
  legacy - [13][a-km-zA-HJ-NP-Z1-9]{27,34}
  bech32 - bc1[a-zA-HJ-NP-Z1-9]{39,62}
*/

const TRON_BLOCKID_REGEX = /(?!0x)0{3,}[0-9a-fA-F]{61,}/
const TRON_TXID_REGEX = /(?!0x)(?!0{3})[0-9a-fA-F]{64}/
const TRON_ADDRESS_REGEX = /T[1-9A-HJ-NP-Za-km-z]{33}/
const ETH_BLOCK_TX_HASH_REGEX = /0x[a-fA-F0-9]{64}/
const ETH_ADDRESS_REGEX = /0x[a-fA-F0-9]{40}/
const BITCOIN_BLOCK_HASH_REGEX = /000000[a-f0-9]{58}/
const BITCOIN_TXID_REGEX = /[a-f0-9]{64}/
const BITCOIN_LEGACY_ADDRESS_REGEX = /[13][a-km-zA-HJ-NP-Z1-9]{25,35}/
const BITCOIN_BECH32_ADDRESS_REGEX = /bc1[qpzry9x8gf2tvdw0s3jn54khce6mua7l]{36,59}/

const ethClassifications = (input: string) => ({
  isEthBlockOrHash: ETH_BLOCK_TX_HASH_REGEX.test(input) && input.length === 66,
  isEthAddress: ETH_ADDRESS_REGEX.test(input) && input.length === 42
})

const tronClassifications = (input: string) => ({
  isTronBlock: TRON_BLOCKID_REGEX.test(input),
  isTronTxid: TRON_TXID_REGEX.test(input),
  isTronAddress: TRON_ADDRESS_REGEX.test(input)
})

const bitcoinClassifications = (input: string) => {
  const results = {
    isBitcoinBlock: BITCOIN_BLOCK_HASH_REGEX.test(input) && input.length === 64,
    isBitcoinTxid: BITCOIN_TXID_REGEX.test(input) && input.length === 64 && !input.startsWith('000000'),
    isBitcoinLegacyAddress:
      BITCOIN_LEGACY_ADDRESS_REGEX.test(input) && !BITCOIN_BECH32_ADDRESS_REGEX.test(input) && input.length <= 36,
    isBitcoinBech32Address: BITCOIN_BECH32_ADDRESS_REGEX.test(input),
    isBitcoinBech32mAddress: BITCOIN_BECH32_ADDRESS_REGEX.test(input) && input.length > 42
  }
  return {
    isBitcoinAddress: results.isBitcoinLegacyAddress || results.isBitcoinBech32Address || results.isBitcoinBech32mAddress,
    ...results
  }
}

export function classifyInput(input: string): NetworkClassifications {
  let networkTypes: NetworkTypes[] = []
  
  // get all chain classifications first in case there's overlap
  const ethereumResults = ethClassifications(input)
  const tronResults = tronClassifications(input)
  const bitcoinResults = bitcoinClassifications(input)
  let classificationResults: any = {}
  if (isBitcoinClassification(bitcoinResults)) {
    classificationResults = { ...classificationResults, ...bitcoinResults }
    networkTypes.push('bitcoin')
  }
  if (isEthereumClassification(ethereumResults)) {
    classificationResults = { ...classificationResults, ...ethereumResults }
    networkTypes.push('ethereum')
  }
  if (isTronClassification(tronResults)) {
    classificationResults = { ...classificationResults, ...tronResults }
    networkTypes.push('tron')
  }

  if (networkTypes.length > 0) {
    return {
      ...classificationResults,
      networkTypes
    }
  }

  return {
    networkTypes
  }
}