import Vue from 'vue'
import Vuex, { Commit } from 'vuex'
import {
  Api,
  ConflictingCategoryResponse,
  EditAddressRequest,
  InsertionState,
  JobStatesResponse,
  NullFieldsResponse,
  ReducedValidationResponse,
  SimilarFields,
  UploadState,
  ValidationResponse,
  ValidationState
} from '../utils/api'
import {
  Attribution,
  StringMap,
  Category,
  User,
  AttributionV2,
  AttributionV3,
  LocalAttribution
} from '../types/attributions'
import { displayPng } from '../utils/download'
import { ensure, mergeMaps } from '../utils/general'

Vue.use(Vuex)

export interface SignupCode {
  code: string
  used: boolean
  date: string
}

export interface ApiKey {
  key: string
  level: number
  username: string
  account: string
  active: boolean
  dateAdded: string
  lastActive: string
}

export interface AttributionCount {
  attributionId: number
  count: number
}

interface AddressStat {
  field: string
  count: number
}

export interface AttributionWithIds {
  name: string
  ids: number[]
  categoryId: number
  user: string
  attributionId: number
  dateAdded?: string
}

interface AddressesStats {
  total: number
  user: AddressStat[]
  network: AddressStat[]
  category: AddressStat[]
}

export interface UserSignupRequest {
  username: string
  password: string
  email: string
  code: string
}

export interface ErrorCauseKeys {
  type: string
  reason?: string
  stack_trace?: string
  caused_by?: ErrorCause
  root_cause?: ErrorCause[]
  suppressed?: ErrorCause[]
}

export type ErrorCause = ErrorCauseKeys & {
  [property: string]: any
}

export interface ElasticSearchError {
  error: ErrorCause
  status: number
}

export function isElasticSearchError(data: unknown): data is ElasticSearchError {
  if (data == null) {
    return false
  }
  return (data as ElasticSearchError).error != null
}

export interface FormattedAutocompleteResponse {
  results: string[]
  count: number
}

export interface SearchInput {
  value: string
}

export type Dataset = 'attributions' | 'addresses'

export interface ElasticSearchInput extends SearchInput {
  dataset: Dataset | 'all'
  mode: 'match' | 'wildcard' | 'suggest'
  resultsCount?: number
  network?: string
}

export interface DatasetResult {
  dataset: Dataset
  result: string
  network?: string
}

export interface FormattedSearchItems {
  [key: string]: string | number | boolean
}

const api = new Api()

const state = {
  version: <number>1,
  username: <string>'',
  signedIn: <boolean>false,
  isAdmin: <boolean>false,
  viewPage: <number>0,
  viewCount: <number>100,
  viewData: <any[]>[],
  viewDataLoaded: <boolean>false,
  writeChunkSize: <number>500,
  // sendRetryAttempts: <number>10,
  // sendProgress: <number>0,
  // sending: <boolean>false,
  // filesSent: <string[]>[],
  signupCodes: <SignupCode[]>[],
  apiKeys: <ApiKey[]>[],
  users: <User[]>[],
  categories: <Category[]>[],
  attributions: <AttributionWithIds[]>[],
  categoryMap: <Map<number, Category> | null>null,
  attributionMap: <Map<number, AttributionV3> | null>null,
  localAttributionMap: new Map<number, AttributionV3>(),
  attributionNameToAttributionMap: <Map<string, AttributionV3> | null>null,
  attributionNameToAllAttributionIds: new Map<string, number[]>(),
  geminiGroups: <string[]>[],
  geminiSourceIds: <string[]>[],
  addressesCount: <number>0,
  addressesStats: <AddressesStats | null>null,
  uniqueAttributions: <string[]>[],
  uniqueAddresses: <string[]>[],
  uniqueNetworks: <string[]>[],
  chunkSize: <number>500,

  // youtube scans
  youtubeScanData: <any[]>[],
  youtubeScanPage: <number>0,
  youtubeScanCount: <number>0,
  youtubeScanSortBy: <string>'createdAt',
  youtubeScanSortDesc: <boolean>false,
  youtubeShowOnlyMyScans: <boolean>false,

  // validation data
  validationResponse: <ValidationResponse | null>null,
  fileValidationResponse: <ReducedValidationResponse | null>null,
  uploadStates: <Map<string, UploadState>>new Map(),
  validationStates: <Map<string, ValidationState>>new Map(),
  insertionStates: <Map<string, InsertionState>>new Map(),
  statesUpdated: <number>0,
  uploadProgress: <Map<string, number>>new Map(),
  uploadUpdate: <number>0,

  dialogMessage: <string>'',
  dialogButton: <string>'',
  dialogVisible: <boolean>false,

  // elastic search & autocomplete
  autocompleteResults: <DatasetResult[]>[],
  autocompleteSearches: <number>0,
  elasticsearchResults: <FormattedSearchItems[]>[]
}

export default new Vuex.Store({
  state,
  mutations: {
    SET_USERNAME(state, { username }: { username: string }) {
      state.username = username
    },
    SET_ADMIN(state, { isAdmin }: { isAdmin: boolean }) {
      state.isAdmin = isAdmin
    },
    SET_SIGNED_IN(state, { signedIn }: { signedIn: boolean }) {
      state.signedIn = signedIn
    },
    SET_VIEW_PAGE(state, { page }: { page: number }) {
      state.viewPage = page
    },
    SET_VIEW_COUNT(state, { count }: { count: number }) {
      state.viewCount = count
    },
    SET_VIEW_DATA(state, { data }: { data: any[] }) {
      state.viewData = data
    },
    SET_VIEW_DATA_LOADED(state, { loaded }: { loaded: boolean }) {
      state.viewDataLoaded = loaded
    },
    // SET_SEND_PROGRESS(state, { progress }: { progress: number }) {
    //   state.sendProgress = progress
    // },
    // SET_SENDING(state, { sending }: { sending: boolean }) {
    //   state.sending = sending
    // },
    // ADD_FILE_SENT(state, { filename }: { filename: string }) {
    //   state.filesSent.push(filename)
    // },
    SET_SIGNUP_CODES(state, { codes }: { codes: SignupCode[] }) {
      state.signupCodes = codes
    },
    SET_API_KEYS(state, { keys }: { keys: ApiKey[] }) {
      state.apiKeys = keys
    },
    SET_USERS(state, { users }: { users: User[] }) {
      state.users = users
    },
    SET_CATEGORIES(state, { categories }: { categories: Category[] }) {
      state.categories = categories
    },
    SET_ATTRIBUTIONS(state) {
      const attributions: AttributionWithIds[] = []
      state.attributionNameToAllAttributionIds.forEach((value, key) => {
        if (state.attributionNameToAttributionMap != null) {
          const masterAttribution: AttributionV3 = ensure(state.attributionNameToAttributionMap.get(key))
          attributions.push({
            name: key,
            ids: value,
            attributionId: masterAttribution.attributionId != null ? masterAttribution.attributionId : -1,
            categoryId: masterAttribution.categoryId,
            user: masterAttribution.user,
            dateAdded: masterAttribution.timeAdded != null ? masterAttribution.timeAdded : ''
          })
        } else {
          console.warn('Attribution name to attribution map is null')
        }
      })
      state.attributions = attributions
    },
    SET_ATTRIBUTION_MAP(state, { attributions }: { attributions: AttributionV3[] }) {
      state.attributionMap = attributions.reduce((merged: Map<number, AttributionV3>, attribution: AttributionV3) => {
        const map = new Map<number, AttributionV3>()
        if (attribution.attributionId != null) {
          map.set(attribution.attributionId, attribution)
        }
        return mergeMaps([merged, map])
      }, new Map<number, AttributionV3>())
      state.attributionNameToAttributionMap = attributions.reduce(
        (merged: Map<string, AttributionV3>, attribution: AttributionV3) => {
          const map = new Map<string, AttributionV3>()
          if (attribution.attributionId != null) {
            map.set(attribution.name, attribution)
          }
          return mergeMaps([merged, map])
        },
        new Map<string, AttributionV3>()
      )
    },
    SET_LOCAL_ATTRIBUTION_MAP(
      state,
      {
        localAttributions,
        activeAttributions
      }: { localAttributions: LocalAttribution[]; activeAttributions: Set<number> }
    ) {
      localAttributions.forEach((localAttribution: LocalAttribution) => {
        if (
          state.attributionMap != null &&
          state.attributionMap.has(localAttribution.masterAttributionId) &&
          activeAttributions.has(localAttribution.localAttributionId)
        ) {
          const masterAttribution = ensure(state.attributionMap.get(localAttribution.masterAttributionId))
          state.localAttributionMap.set(localAttribution.localAttributionId, masterAttribution)

          let current: number[] = []
          if (state.attributionNameToAllAttributionIds.has(masterAttribution.name)) {
            current = ensure(state.attributionNameToAllAttributionIds.get(masterAttribution.name))
          }
          current.push(localAttribution.localAttributionId)
          state.attributionNameToAllAttributionIds.set(masterAttribution.name, current)
        }
      })
    },
    SET_CATEGORY_MAP(state, { categories }: { categories: Category[] }) {
      state.categoryMap =
        categories.reduce((merged: Map<number, Category>, category: Category) => {
          const map = new Map<number, Category>()
          if (category.id != null) {
            map.set(category.id, category)
          }
          return new Map([...merged, ...map])
        }, new Map<number, Category>()) ?? new Map<number, Category>()
    },
    SET_GEMINI_GROUPS(state, { groups }: { groups: string[] }) {
      state.geminiGroups = groups
    },
    SET_GEMINI_SOURCE_IDS(state, { ids }: { ids: string[] }) {
      state.geminiSourceIds = ids
    },
    SET_ADDRESSES_STATS(state, { stats }: { stats: AddressesStats }) {
      state.addressesStats = stats
    },
    SET_ADDRESSES_COUNT(state, { count }: { count: number }) {
      state.addressesCount = count
    },
    SET_UNIQUE_ATTRIBUTIONS(state, { unique }: { unique: string[] }) {
      state.uniqueAttributions = unique
    },
    SET_UNIQUE_ADDRESSES(state, { unique }: { unique: string[] }) {
      state.uniqueAddresses = unique
    },
    SET_UNIQUE_NETWORKS(state, { unique }: { unique: string[] }) {
      state.uniqueNetworks = unique
    },
    // youtube scans
    SET_YOUTUBE_SCAN_DATA(state, { scans }: { scans: any[] }) {
      state.youtubeScanData = scans
    },
    SET_YOUTUBE_SCAN_PAGE(state, { page }: { page: number }) {
      state.youtubeScanPage = page
    },
    SET_YOUTUBE_SCAN_COUNT(state, { count }: { count: number }) {
      state.youtubeScanCount = count
    },
    SET_YOUTUBE_SCAN_SORT_BY(state, { sortBy }: { sortBy: string }) {
      state.youtubeScanSortBy = sortBy
    },
    SET_YOUTUBE_SCAN_SORT_DESC(state, { sortDesc }: { sortDesc: boolean }) {
      state.youtubeScanSortDesc = sortDesc
    },
    SET_YOUTUBE_SHOW_ONLY_MY_SCANS(state, { showOnlyMyScans }: { showOnlyMyScans: boolean }) {
      state.youtubeShowOnlyMyScans = showOnlyMyScans
    },
    SET_VALIDATION_RESPONSE(state, { response }: { response: ValidationResponse }) {
      state.validationResponse = response
    },
    SET_FILE_VALIDATION_RESPONSE(state, { response }: { response: ReducedValidationResponse }) {
      state.fileValidationResponse = response
      state.statesUpdated++
    },
    SET_UPLOAD_STATE(state, { jobId, data }: { jobId: string; data: UploadState }) {
      state.uploadStates.set(jobId, data)
      state.statesUpdated++
    },
    SET_VALIDATION_STATE(state, { jobId, data }: { jobId: string; data: ValidationState }) {
      state.validationStates.set(jobId, data)
      state.statesUpdated++
    },
    SET_INSERTION_STATE(state, { jobId, data }: { jobId: string; data: InsertionState }) {
      state.insertionStates.set(jobId, data)
      state.statesUpdated++
    },
    SET_UPLOAD_PROGRESS(state, { jobId, filename, progress }: { jobId: string; filename: string; progress: number }) {
      const uploadId = `${jobId}-${filename}`
      state.uploadProgress.set(uploadId, progress)
      state.uploadUpdate++
      state.statesUpdated++
    },
    SET_ALL_JOB_STATES(state, { jobStates }: { jobStates: JobStatesResponse }) {
      const { upload, validation, insertion } = jobStates
      for (const { jobId, ...status } of upload) {
        state.uploadStates.set(jobId, status)
      }
      for (const { jobId, ...status } of validation) {
        state.validationStates.set(jobId, status)
      }
      for (const { jobId, ...status } of insertion) {
        state.insertionStates.set(jobId, status)
      }
      state.statesUpdated++
    },
    RESET_JOB_STATES(state) {
      state.uploadStates.clear()
      state.validationStates.clear()
      state.insertionStates.clear()
      state.uploadProgress.clear()
      state.uploadUpdate++
      state.statesUpdated++
    },
    SET_MESSAGE_DIALOG(state, { message, button, show }: { message: string; button: string; show: boolean }) {
      state.dialogMessage = message
      state.dialogButton = button
      state.dialogVisible = show
    },
    // elastic search & autocomplete
    SET_AUTOCOMPLETE_RESULTS(state, { results }: { results: DatasetResult[] }) {
      state.autocompleteResults = results
      state.autocompleteSearches += 1
    },
    SET_ELASTICSEARCH_RESULTS(state, { results }: { results: FormattedSearchItems[] }) {
      state.elasticsearchResults = results
    }
  },
  actions: {
    // auth
    async signIn({ commit }: { commit: Commit }, { username, password }: { username: string; password: string }) {
      const response = await api.signIn(username, password)
      if (response.success) {
        commit('SET_USERNAME', { username })
        commit('SET_SIGNED_IN', { signedIn: true })
        api.userSeen(username)
        const userResponse = await api.getUser(username)
        if (userResponse.success && userResponse.data != null) {
          if (userResponse.data.isAdmin) {
            commit('SET_ADMIN', { isAdmin: true })
          }
        }
      }
    },
    async checkAuth({ commit }: { commit: Commit }) {
      const response = await api.checkAuth()
      if (response.success) {
        commit('SET_USERNAME', { username: response.username })
        commit('SET_SIGNED_IN', { signedIn: true })
        const userResponse = await api.getUser(state.username)
        if (userResponse.success && userResponse.data != null) {
          if (userResponse.data.isAdmin) {
            commit('SET_ADMIN', { isAdmin: true })
          }
        }
      }
    },
    signOut({ commit }: { commit: Commit }) {
      commit('SET_USERNAME', { username: '' })
      commit('SET_SIGNED_IN', { signedIn: false })
      api.signOut()
    },
    async signUp({ commit }: { commit: Commit }, { username, password, email, code }: UserSignupRequest) {
      await api.signUp(username, password, email, code)
      await this.dispatch('getUsers')
    },
    // admin
    async getUsers({ commit }: { commit: Commit }) {
      const response = await api.getUsers()
      if (response != null) {
        commit('SET_USERS', { users: response.data })
      }
    },
    async getSignupCodes({ commit }: { commit: Commit }) {
      const response = await api.getSignupCodes()
      if (response != null) {
        commit('SET_SIGNUP_CODES', { codes: response.data })
      }
    },
    async genSignupCodes({ commit }: { commit: Commit }) {
      if (state.signedIn) {
        await api.genSignupCodes()
        await this.dispatch('getSignupCodes')
      }
    },
    async getApiKeys({ commit }: { commit: Commit }) {
      await api.getApiKeys().then((response) => {
        if (response.success) {
          commit('SET_API_KEYS', { keys: response.data })
        }
      })
    },
    async genApiKey({ commit }: { commit: Commit }) {
      if (state.signedIn) {
        await api.genApiKey(state.username)
      }
    },
    // addresses
    clearAddresses({ commit }: { commit: Commit }) {
      commit('SET_VIEW_DATA', { data: [] })
      commit('SET_VIEW_PAGE', { page: 0 })
      commit('SET_VIEW_DATA_LOADED', { loaded: false })
    },
    async getAddresses(
      { commit }: { commit: Commit },
      { page, count, query = {} }: { page: number; count: number; query: StringMap }
    ) {
      if (state.signedIn) {
        const response = await api.getAddresses(page, count, query)
        if (response.success) {
          commit('SET_VIEW_PAGE', { page })
          commit('SET_VIEW_DATA', { data: response.data.results })
          commit('SET_VIEW_DATA_LOADED', { loaded: true })
        }
      }
    },
    showMessageDialog(
      { commit }: { commit: Commit },
      { message, button, show }: { message: string; button: string; show: boolean }
    ) {
      commit('SET_MESSAGE_DIALOG', { message, button, show })
    },
    async mergeAttributions(
      { commit }: { commit: Commit },
      { mainAttribution, attributionsToMerge }: { mainAttribution: AttributionV3; attributionsToMerge: AttributionV3[] }
    ) {
      const response = await api.mergeAttributions(mainAttribution, attributionsToMerge)
      // commit('SET_MERGED_RESPONSE', { count: response }) // this goes to nowhere
    },
    async uploadFile({ commit }: { commit: Commit }, { data }: { data: FormData }) {
      const onUploadProgress = (progressEvent: ProgressEvent) => {
        const progress = progressEvent.loaded / progressEvent.total
        commit('SET_UPLOAD_PROGRESS', { progress })
      }
      return api.uploadAttributionsFile(data, state.username, onUploadProgress)
    },
    async validateFiles({ commit }: { commit: Commit }, { jobId, filenames }: { jobId: string; filenames: string[] }) {
      return api.validateAttributionsFiles(jobId, state.username, filenames)
    },
    async insertAttributionsFiles(
      { commit }: { commit: Commit },
      { jobId, filenames }: { jobId: string; filenames: string[] }
    ) {
      return api.insertAttributionsFiles(jobId, state.username, filenames)
    },
    async getUploadState({ commit }: { commit: Commit }, jobId: string) {
      const uploadStateResponse = await api.getJobUploadState(jobId)
      const { success, data } = uploadStateResponse
      if (success && data != null) {
        commit('SET_UPLOAD_STATE', { jobId, data })
        return data
      }
    },
    async getValidationState({ commit }: { commit: Commit }, jobId: string) {
      const validationStateResponse = await api.getJobValidationState(jobId)
      const { success, data } = validationStateResponse
      if (success && data != null) {
        commit('SET_VALIDATION_STATE', { jobId, data })
        return data
      }
    },
    async getInsertionState({ commit }: { commit: Commit }, jobId: string) {
      const insertionStateResponse = await api.getJobInsertionState(jobId)
      const { success, data } = insertionStateResponse
      if (success && data != null) {
        commit('SET_INSERTION_STATE', { jobId, data })
        return data
      }
    },
    async listAllValidationJobs({ commit }: { commit: Commit }) {
      const { success, data: jobStates } = await api.listAllValidationJobs()
      if (success && jobStates != null) {
        commit('RESET_JOB_STATES')
        commit('SET_ALL_JOB_STATES', { jobStates })
        return jobStates
      }
    },
    async deleteValidationJob({ commit }: { commit: Commit }, jobId: string) {
      console.log(`deleting ${jobId}`)
      await api.deleteJob(jobId)
      commit('RESET_JOB_STATES')
    },
    async addAddress({ commit }: { commit: Commit }, { item }: { item: Attribution }) {
      await api.addAddress(item)
    },
    async editAddress({ commit }: { commit: Commit }, { edited }: { edited: EditAddressRequest }) {
      await api.editAddress([edited])
    },
    async deleteAddress({ commit }: { commit: Commit }, { item }: { item: AttributionV2 }) {
      await api.deleteAddress(item)
    },
    async getAddressesCount(
      { commit }: { commit: Commit },
      { query = {}, estimate = false }: { query: StringMap; estimate: boolean }
    ) {
      const response = await api.getAddressesCount(query, estimate)
      if (response.success) {
        commit('SET_ADDRESSES_COUNT', { count: response.data.count })
        commit('SET_VIEW_COUNT', { count: response.data.count })
      }
    },
    async getAddressesStats({ commit }: { commit: Commit }) {
      const response = await api.getAddressesStats()
      if (response.success) {
        commit('SET_ADDRESSES_STATS', { stats: response.data })
      }
    },
    async refreshAddressStats({ commit }: { commit: Commit }) {
      const response = await api.refreshAddressStats()
    },
    async getUniqueAttributions({ commit }: { commit: Commit }) {
      commit('SET_UNIQUE_ATTRIBUTIONS', { unique: [] })
      const response = await api.getUniqueAttributions()
      if (response.success) {
        commit('SET_UNIQUE_ATTRIBUTIONS', { unique: response.data })
      }
    },
    async getUniqueAddresses({ commit }: { commit: Commit }, { source }: { source: string }) {
      commit('SET_UNIQUE_ADDRESSES', { unique: [] })
      const response = await api.getUniqueAddresses(source)
      if (response.success) {
        commit('SET_UNIQUE_ADDRESSES', { unique: response.data })
      }
    },
    clearUniqueAddresses({ commit }: { commit: Commit }) {
      commit('SET_UNIQUE_ADDRESSES', { unique: [] })
    },
    async getUniqueNetworks({ commit }: { commit: Commit }, { source }: { source: string }) {
      commit('SET_UNIQUE_NETWORKS', { unique: [] })
      const response = await api.getUniqueNetworks(source)
      if (response.success) {
        const unique = response.data.map((x: string) => x.replace(/\W/g, ''))
        commit('SET_UNIQUE_NETWORKS', { unique })
      }
    },
    async getUniqueNetworksForAttribution(
      { commit }: { commit: Commit },
      { attributionId }: { attributionId: number }
    ) {
      commit('SET_UNIQUE_NETWORKS', { unique: [] })
      const attributionMapping = await api.getLocalAttributionsMapping([attributionId])
      if (attributionMapping) {
        const localAttributions = attributionMapping.data.map((x: LocalAttribution) => x.localAttributionId)
        const response = await api.getUniqueNetworksForAttribution(localAttributions)
        if (response.success) {
          const unique = response.data.map((x: string) => x.replace(/\W/g, ''))
          commit('SET_UNIQUE_NETWORKS', { unique })
        }
      }
    },
    async getAttributions({ commit }: { commit: Commit }) {
      commit('SET_VIEW_DATA_LOADED', { loaded: false })
      const totalMaster = (await api.getAttributionCount(false)).data.count
      const totalLocal = (await api.getAttributionCount(true)).data.count
      const allAttributions: AttributionV3[] = []
      const allLocals: LocalAttribution[] = []
      const activeLocals: AttributionCount[] = []
      function updateFunction(results: any[], status: string) {
        if (status === 'local') {
          allLocals.push(...(results as LocalAttribution[]))
        } else if (status === 'master') {
          allAttributions.push(...(results as AttributionV3[]))
        } else {
          activeLocals.push(...(results as AttributionCount[]))
        }
      }
      const masterResp = await api.getAllAttributions(totalMaster, 3, 5000, 'master', updateFunction)
      if (masterResp.success) {
        const localResp = await api.getAllAttributions(totalLocal, 3, 5000, 'local', updateFunction)
        await api.getAllAttributions(totalLocal, 3, 5000, 'active', updateFunction)
        const actives: Set<number> = new Set<number>()
        activeLocals.forEach((x: AttributionCount) => {
          actives.add(x.attributionId)
        })
        if (localResp.success) {
          commit('SET_ATTRIBUTION_MAP', { attributions: allAttributions })
          commit('SET_LOCAL_ATTRIBUTION_MAP', { localAttributions: allLocals, activeAttributions: actives })
          commit('SET_ATTRIBUTIONS')
        }
      }
      commit('SET_VIEW_DATA_LOADED', { loaded: true })
    },
    async addAttribution({ commit }: { commit: Commit }, { attribution }: { attribution: AttributionV3 }) {
      attribution = { ...attribution, user: state.username }
      const addResponse = await api.addAttribution(attribution)
      if (addResponse.success) {
        const getResponse = await api.getAttributions(0, 5000, {})
        const getLocalsResponse = await api.getLocalAttributions(0, 5000)
        const activeResponse = await api.getActiveAttributions(0, 5000)
        const actives: Set<number> = activeResponse.data.results.reduce((acc: Set<number>, count: AttributionCount) => {
          acc.add(count.attributionId)
          return acc
        }, new Set<number>())
        if (getResponse.success) {
          commit('SET_ATTRIBUTIONS', { attributions: getResponse.data.results })
          commit('SET_ATTRIBUTION_MAP', { attributions: getResponse.data.results })
          commit('SET_LOCAL_ATTRIBUTION_MAP', {
            localAttributions: getLocalsResponse.data.results,
            activeAttributions: actives
          })
        }
      }
    },
    async addAttributions({ commit }: { commit: Commit }, { attributions }: { attributions: AttributionV3[] }) {
      const addResponse = await api.addAttributions(attributions)
      if (addResponse.success) {
        const getResponse = await api.getAttributions(0, 5000, {})
        if (getResponse.success) {
          commit('SET_ATTRIBUTIONS', { attributions: getResponse.data.results })
          commit('SET_ATTRIBUTION_MAP', { attributions: getResponse.data.results })
        }
      }
    },
    // categories
    async getCategories({ commit }: { commit: Commit }, { source }: { source: string }) {
      const response = await api.getCategories(source)
      if (response.success) {
        commit('SET_CATEGORIES', { categories: response.data })
        commit('SET_CATEGORY_MAP', { categories: response.data })
      }
    },
    addCategory(
      { commit }: { commit: Commit },
      { category, source = 'internal' }: { category: Category; source: string }
    ) {
      category = { ...category, user: state.username }
      api.addCategory(category).then((response) => {
        if (response.success) {
          api.getCategories(source).then((response) => {
            if (response.success) {
              commit('SET_CATEGORIES', { categories: response.data })
              commit('SET_CATEGORY_MAP', { categories: response.data })
            }
          })
        }
      })
    },
    async getGeminiGroups({ commit }: { commit: Commit }) {
      const response = await api.getGeminiGroups()
      if (response.success) {
        commit('SET_GEMINI_GROUPS', { groups: response.data })
      }
    },
    async getGeminiSourceIds({ commit }: { commit: Commit }) {
      const response = await api.getGeminiSourceIds()
      if (response.success) {
        commit('SET_GEMINI_SOURCE_IDS', { ids: response.data })
      }
    },
    // youtube scans
    async getYoutubeScans(
      { commit }: { commit: Commit },
      {
        page,
        count,
        sortBy,
        sortDesc,
        showOnlyMyScans
      }: { page: number; count: number; sortBy: string; sortDesc: boolean; showOnlyMyScans: boolean }
    ) {
      if (state.signedIn) {
        await api
          .getYouTubeScans(page, count, sortBy, sortDesc, showOnlyMyScans ? state.username : undefined)
          .then((response) => {
            if (response.success) {
              commit('SET_YOUTUBE_SCAN_PAGE', { page })
              commit('SET_YOUTUBE_SCAN_DATA', { scans: response.data })
              commit('SET_YOUTUBE_SCAN_SORT_BY', { sortBy })
              commit('SET_YOUTUBE_SCAN_SORT_DESC', { sortDesc })
              commit('SET_YOUTUBE_SHOW_ONLY_MY_SCANS', { showOnlyMyScans })
            }
          })
      }
    },
    async getYoutubeScansCount({ commit }: { commit: Commit }, { showOnlyMyScans }: { showOnlyMyScans: boolean }) {
      const response = await api.getYouTubeScansCount(showOnlyMyScans ? state.username : undefined)
      if (response.success) {
        commit('SET_YOUTUBE_SCAN_COUNT', { count: response.data.count })
      }
    },
    // async setYouTubeSortBy({ commit }: { commit: Commit }, { sortBy }: { sortBy: string }) {
    //   commit('SET_YOUTUBE_SCAN_SORT_BY', { sortBy })
    // },
    // async setYouTubeSortDesc({ commit }: { commit: Commit }, { sortDesc }: { sortDesc: boolean }) {
    //   commit('SET_YOUTUBE_SCAN_SORT_DESC', { sortDesc })
    // },
    async dispatchLambda({ commit }: { commit: Commit }, { link }: { link: string }) {
      if (state.signedIn) {
        await api.dispatchLambda(link.slice(link.length - 11), state.username)
      }
    },
    async dispatchQuery({ commit }: { commit: Commit }, { query, numVideos }: { query: string; numVideos: number }) {
      if (state.signedIn) {
        await api.dispatchQuery(query, numVideos, state.username)
      }
    },
    async dispatchQueries(
      { commit }: { commit: Commit },
      { queries, videosPerQuery }: { queries: string[]; videosPerQuery: number }
    ) {
      if (state.signedIn) {
        await api.dispatchQueries(queries, videosPerQuery, state.username)
      }
    },
    async downloadFrame({ commit }: { commit: Commit }, { videoId, addr }: { videoId: string; addr: string }) {
      if (state.signedIn) {
        const res = await api.downloadFrame(videoId, addr)
        if (res) {
          displayPng(res)
        }
      }
    },
    // elastic search & autocomplete
    async executeAutocomplete(
      { commit }: { commit: Commit },
      { dataset, value, mode = 'wildcard', network }: ElasticSearchInput
    ) {
      if (typeof value === 'string' && value.length > 0 && mode !== 'match') {
        const results: DatasetResult[] = []
        if (dataset !== 'all') {
          const autocompleteResponse = await api.autocomplete({
            dataset,
            payload: {
              searchString: value,
              mode,
              network
            }
          })
          if (isElasticSearchError(autocompleteResponse)) {
            console.warn(autocompleteResponse)
            return
          }
          const { results: rawResults } = autocompleteResponse.data as FormattedAutocompleteResponse
          let formattedDataset: Dataset
          switch (dataset) {
            case 'attributions':
              formattedDataset = 'addresses'
              break
            default:
              formattedDataset = dataset
          }
          for (const result of rawResults) {
            results.push({
              dataset: formattedDataset,
              result,
              network
            })
          }
        } else {
          const datasets: Dataset[] = ['attributions']
          const searchDatasets: {
            dataset: Dataset
            network?: string
          }[] = []
          // use unshift to add attribution datasets to get them at the front of the result list
          if (network === 'all') {
            searchDatasets.unshift(...datasets.map((d) => ({ dataset: d, network: '*' })))
          } else {
            searchDatasets.unshift(...datasets.map((d) => ({ dataset: d, network })))
          }
          await Promise.all(
            searchDatasets.map((networkDataset) =>
              api.autocomplete({
                dataset: networkDataset.dataset,
                payload: {
                  searchString: value,
                  mode,
                  network: networkDataset.network,
                  results: 10
                }
              })
            )
          ).then((autocompleteResponses) => {
            for (const [index, autocompleteResponse] of autocompleteResponses.entries()) {
              if (isElasticSearchError(autocompleteResponse)) {
                console.warn(autocompleteResponse)
              } else {
                const { results: rawResults } = autocompleteResponse.data as FormattedAutocompleteResponse
                let formattedDataset: Dataset
                const { dataset: currentDataset, network: currentNetwork } = searchDatasets[index]
                switch (currentDataset) {
                  case 'attributions':
                    formattedDataset = 'addresses'
                    break
                  default:
                    formattedDataset = currentDataset
                }
                for (const result of rawResults) {
                  results.push({
                    dataset: formattedDataset,
                    result,
                    network: currentNetwork
                  })
                }
              }
            }
          })
        }
        commit('SET_AUTOCOMPLETE_RESULTS', { results })
      } else if (mode === 'match') {
        console.warn('mode cannot be match for autocomplete')
      } else {
        console.warn('autocomplete called with empty string')
      }
    },
    clearAutocomplete({ commit }: { commit: Commit }) {
      commit('SET_AUTOCOMPLETE_RESULTS', { results: [] })
    }
  },
  getters: {
    addresses: (state) => state.viewData,
    youtubeScans: (state) => {
      return state.youtubeScanData
    }
  },
  modules: {}
})
