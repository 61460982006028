














































































import { Component, Vue, Prop } from 'vue-property-decorator'
import Table, { Header } from '@/components/Table.vue'
import MergeAttributionsDialog from '@/components/MergeAttributionsDialog.vue'
import { AttributionV3, Category } from '../types/attributions'
import { titleCase } from '@/utils/general'
import 'vue-multiselect/dist/vue-multiselect.min.css'

@Component({
  components: {
    Table,
    MergeAttributionsDialog
  }
})
export default class Admin extends Vue {
  public categoryHeaders: Header[] = [
    {text: 'ID', filterable: true, value: 'id'},
    {text: 'Name', filterable: true, value: 'name'},
    {text: 'Code', filterable: true, value: 'code'},
    {text: 'Added By', filterable: true, value: 'user'},
    {text: 'Added', filterable: true, value: 'dateAdded'}
  ]  
  public attributionHeaders: Header[] = [
    {text: 'ID', filterable: true, value: 'attributionId'},
    {text: 'Name', filterable: true, value: 'name'},
    {text: 'CategoryID', filterable: true, value: 'categoryId'},
    {text: 'Added By', filterable: true, value: 'user'},
    {text: 'Added', filterable: true, value: 'dateAdded'}
  ]
  public addressByCategoryHeaders: Header[] = [
    { text: 'Category', filterable: false, value: 'field' },
    { text: 'Count', filterable: false, value: 'count' }
  ]
  public addressByNetworkHeaders: Header[] = [
    { text: 'Network', filterable: false, value: 'field' },
    { text: 'Count', filterable: false, value: 'count' }
  ]
  public addressByUserHeaders: Header[] = [
    { text: 'User', filterable: false, value: 'field' },
    { text: 'Count', filterable: false, value: 'count' }
  ]
  public rules = {
    required: (value: string) => !!value || 'Required',
    min: (value: string) => value.length >= 2 || 'Min 2 characters'
  }
  public categoryId: number | null = null
  public categoryName: string = ''
  public categoryCode: string = ''  

  public attributionId: number | null = null
  public attributionName: string = ''
  public attributionCategory: number = 0

  public catAttToggle: string = 'categories'
  public loading = true
  // private showMergeAttributions

  async created() {
    await this.getCategories()
    await this.getAttributions()
    await this.getAddressesStats()
    this.loading = false
  }

  get categorySubmitDisabled() {
    if (this.catAttToggle == 'categories') {
      return this.categoryName === '' || this.categoryCode === ''
    } else {
      return this.attributionName === '' || this.attributionCategory === null
    }
  }

  get categories() {
    return this.$store.state.categories
  }

  get attributions() {
    return this.$store.state.attributions
  }

  get categoryMap() {
    return this.$store.state.categoryMap
  }

  get localAttributionMap(): Map<number, AttributionV3> {
    return this.$store.state.localAttributionMap
  }

  get addressesStatsTotal() {
    if (this.$store.state.addressesStats == null) {
      return 0
    }
    return this.$store.state.addressesStats.total
  }

  get addressesStatsUsers() {
    if (this.$store.state.addressesStats == null) {
      return []
    }
    
    return this.$store.state.addressesStats.user
  }

  get addressesStatsNetworks() {
    if (this.$store.state.addressesStats == null) {
      return []
    }
    
    const byNetwork = this.$store.state.addressesStats.network
    return byNetwork
  }

  get addressesStatsCategories() {
    if (this.$store.state.addressesStats == null) {
      return []
    }
    const filtered = this.$store.state.addressesStats.category.map(({field, count}: {field: string, count: number}) => {
      const categoryId = parseInt(field, 10)
      if(this.$store.state.categoryMap.get(categoryId)) {
        return {field: this.$store.state.categoryMap.get(categoryId).name, count: count}
      }
    });
    return filtered
  }

  resolveCategoryName(value: number) {
    if (this.localAttributionMap) {
      const attribution: AttributionV3 | undefined = this.localAttributionMap.get(value)
      if (attribution) {
        const category: Category | undefined = this.categoryMap.get(attribution.categoryId)
        if (category) {
          return category.name
        }
      }
      return 'UNKNOWN'
    } else {
      return 'N/A'
    }
  }

  resolveAttributionName(value: number) {
    if (this.localAttributionMap) {
      const attribution: AttributionV3 | undefined = this.localAttributionMap.get(value)
      if (attribution) {
        return attribution.name
      }
      return 'UNKNOWN'
    } else {
      return 'N/A'
    }
  }

  public getTableData() {
    if (this.catAttToggle == 'categories') {
      return this.$store.dispatch('getCategories', {source: 'internal'})
    } else {
      return this.$store.dispatch('getAttributions')
    }
  }


  private getCategories() {
    return this.$store.dispatch('getCategories', {source: 'internal'})
  }

  private getAttributions() {
    return this.$store.dispatch('getAttributions')
  }

  public getAddressesStats() {
    return Promise.all([
      this.$store.dispatch('getAddressesStats'),
      this.$store.dispatch('refreshAddressStats')
    ])
  }

  public adminTitleCase(text: string) {
    return titleCase(text)
  }

  public async handleSubmitField() {
    if (this.catAttToggle === 'categories') {
      const category = {
        id: this.categoryId,
        name: this.categoryName,
        code: this.categoryCode
      }
      await this.$store.dispatch('addCategory', {category})
    } else {
      const attribution = {
        attributionId: this.attributionId,
        name: this.attributionName,
        categoryId: this.attributionCategory
      }
      await this.$store.dispatch('addAttribution', {attribution})
    }
  }

  public async mergeAttributions() {
    this.showMergeDialog()
    await this.getAttributions()
  }

  private showMergeDialog() {
    (<MergeAttributionsDialog>this.$refs.addDialog).open({
        attributionId: (this.attributionId) ? this.attributionId : undefined ,
        categoryId: this.attributionCategory,
        name: this.attributionName,
        user: this.$store.state.username
      })
  }

  public onCategoryClicked(item: Category) {
    this.categoryId = item.id ?? null
    this.categoryName = item.name
    this.categoryCode = item.code
  }

  public onAttributionClicked(item: AttributionV3) {
    this.attributionId = item.attributionId ?? null
    this.attributionName = item.name
    this.attributionCategory = item.categoryId
  }

}
